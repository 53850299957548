import React, { useEffect, useState } from "react";

const ThreeImageComp = ({ descriptions }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const titleStyle = {
    color: "var(--white, #FFF)",
    textAlign: "center",
    fontFamily: "Candal",
    fontSize: isMobile ? "32px" : "64px",
    fontStyle: "normal",
    lineHeight: "78%", // 49.92px
    textTransform: "uppercase",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    marginBottom: "2rem",
  };

  const textStyle = {
    color: "var(--white, #FFF)",
    textAlign: "center",
    fontFamily: "KHTeka",
    fontSize: isMobile ? "16px" : "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "100%", // 24px
  };

  return (
    <div
      className={`description-section flex ${
        isMobile ? "flex-col" : "justify-between p-5"
      } items-start  bg-black`}
    >
      {descriptions.map((desc, index) => (
        <div
          key={index}
          className={`description-item flex items-center flex-col flex-grow
          p-10`}
          style={{
            flexBasis: "0%",
            height: "100%",
            marginBottom: isMobile ? "1rem" : "0",
          }}
        >
          <img
            className="description-image mb-5"
            src={desc.img}
            alt={desc.title}
            style={{
              height: "auto",
              maxHeight: "100%",
            }}
          />
          <h2 style={titleStyle}>{desc.title}</h2>
          <p style={textStyle}>{desc.text}</p>
        </div>
      ))}
    </div>
  );
};

export default ThreeImageComp;
