import React, { useEffect, useState } from "react";
import ThreeImageComp from "./ThreeImageComp";

// Importing images
import c1 from "../assets/img/campaign1.webp";
import c2 from "../assets/img/campaign2.webp";
import c3 from "../assets/img/campaign3.webp";

const descriptions = [
  {
    img: c1,
    title: "COMMUNITY",
    text: "Users’ ID must be verified to access the platform. Women have each other’s backs - we want to build a community around this.",
  },
  {
    img: c2,
    title: "FLEXIBILITY",
    text: "Our platform will empower women to enjoy their night without worrying about travelling alone. Everyone deserves stress-free nights out! ",
  },
  {
    img: c3,
    title: "SAVINGS",
    text: "Safety shouldn't be a luxury. By sharing rides women can keep each other safe and split the cost of travelling.",
  },
];

const DescriptionSection = () => {
  return <ThreeImageComp descriptions={descriptions} />;
};

export default DescriptionSection;
