import React, { useState, useEffect } from "react";
import HeroText from "../assets/img/HeroImage.svg";
import HeroTextMobile from "../assets/img/HeroImage_website.webp";
import arrow from "../assets/img/arrowdown.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../assets/img/logo.svg";
import MenuIcon from "../assets/img/menu_icon.svg";
import MenuClose from "../assets/img/menu_close.svg";
import Download from "../assets/img/download_button.svg";
import { markDownloadClicked } from "../functions/analytics";

const HeroSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDownloadClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = (e) => {
    if (e.target.id === "popupBackground") {
      setIsPopupOpen(false);
    }
  };

  return (
    <div className="flex flex-col justify-between items-center w-screen h-screen p-4 lg:p-16 bg-black">
      <div></div>
      <img
        src={isMobile ? HeroTextMobile : HeroText}
        alt="Hero"
        className={`max-h-3/4 ${isMobile ? "w-3/4" : "w-3/4"}`}
        style={{ marginTop: '100px' }} // Adjust the value as needed
      />

      <div className="text-center">
        <button
          style={{
            border: "1px solid #000",
            background: "var(--BRAND-COLOURS-PINK, #F48F14)",
            color: "#000",
            fontFamily: "KHTeka",
            fontSize: isMobile ? "18px" : "24px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "100%",
            letterSpacing: "-0.4px",
          }}
          type="button"
          onClick={handleDownloadClick}
          className={`bg-[#F48F14] ${
            !isMobile ? "p-4 px-8" : "py-2 px-3"
          } rounded-full text-black font-bold mb-4 lg:mb-20 w-full`}
        >
          DOWNLOAD
        </button>
      </div>

      {isPopupOpen && (
        <div
          id="popupBackground"
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
          onClick={handleClosePopup}
        >
          <div className="bg-black p-6 rounded-lg text-center">
            <button
              className="bg-blue-500 text-white px-4 py-2 m-2 rounded"
              onClick={() => {
                markDownloadClicked("ios", "main_screen");
                window.open(
                  "https://apps.apple.com/us/app/get-home-safe/id6473676583",
                  "_blank"
                );
                setIsPopupOpen(false);
              }}
            >
              iOS
            </button>
            <button
              className="bg-green-500 text-white px-4 py-2 m-2 rounded"
              onClick={() => {
                markDownloadClicked("android", "main_screen");
                window.open(
                  "https://play.google.com/store/apps/details?id=io.gethomesafe.reactnative",
                  "_blank"
                );
                setIsPopupOpen(false);
              }}
            >
              Android
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroSection;
