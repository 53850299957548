import React from "react";
import ThreeImageComp from "./ThreeImageComp";

// // Importing images
// import verifyImage from "../assets/img/verify-yourself.png";
// import planRidesImage from "../assets/img/plan-rides.png";
// import meetupImage from "../assets/img/meetup.png";

import d1 from "../assets/img/description1.webp";
import d2 from "../assets/img/description2.webp";
import d3 from "../assets/img/description3.webp";

const descriptions = [
  {
    img: d1,
    title: "SIGN UP",
    text: "Join the community by entering some basic details. You can then verify your ID and university for added security.",
  },
  {
    img: d2,
    title: "PLAN RIDES",
    text: "Schedule a ride and use filters to select who can join. Our algorithm will recommend compatible co-riders and you choose who to travel with.",
  },
  {
    img: d3,
    title: "MEETUP",
    text: "Once it’s time to leave, co-riders meet up in person, book the ride and split the cost through our app.",
  },
];

const DescriptionSection = () => {
  return <ThreeImageComp descriptions={descriptions} />;
};

export default DescriptionSection;
